<template>
  <div
    v-if="flatGallery.length"
    class="image-carousel-wrapper"
  >
    <T3HtmlParser
      v-if="header"
      class="image-carousel-header"
      :content="header"
    />

    <div
      class="image-carousel"
      :style="{ '--carousel-size': flatGallery.length }"
    >
      <div class="image-carousel__slider">
        <div
          v-for="image in flatGallery"
          :key="image.id"
          class="image-carousel__slide-wrapper"
        >
          <UiImg
            :image="image"
            :width="150"
            class="image-carousel__slide"
            disable-placeholder
          />
        </div>

        <div
          v-for="image in flatGallery"
          :key="image.id + '-duplicated'"
          class="image-carousel__slide-wrapper"
        >
          <UiImg
            :image="image"
            :width="150"
            class="image-carousel__slide"
            disable-placeholder
            aria-hidden="true"
            decorative
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UiImg } from '~ui/components'
import useFlatGallery from '~ui/composables/useFlatGallery'
import type { UiCeTextGalleryProps } from '~ui/types'

const props = defineProps<UiCeTextGalleryProps>()

const flatGallery = useFlatGallery(props)
</script>

<style lang="scss">
.image-carousel {
  $imageWidth: 150px;

  &-wrapper {
    display: flex;
    align-items: center;
  }

  &-header {
    position: relative;
    flex-shrink: 0;
    padding-right: rem(48px);
    color: color(grey-800);
    font-size: rem(18px);

    @include media-query(max sm) {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: calc($imageWidth / 3);
      top: 50%;
      right: rem(24px);
      translate: 0 -50%;
      background-color: color(grey-300);
    }
  }

  height: calc($imageWidth / 3);
  max-width: 100%;
  margin-block: rem(32px);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: linear-gradient(
      90deg,
      var(--color__background-color, color(white)) 0%,
      transparent 15%,
      transparent 85%,
      var(--color__background-color, color(white)) 100%
    );
    z-index: z-index(over);
    pointer-events: none;
  }

  &__slider {
    display: flex;
    gap: calc($imageWidth / 1.5);
    width: max-content;
    animation: carousel calc(var(--carousel-size) * 2s) forwards linear infinite;
  }

  &__slide-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__slide {
    height: calc($imageWidth / 3);
    width: $imageWidth;
    object-fit: contain;
  }

  @keyframes carousel {
    to {
      transform: translate(calc(-50% - $imageWidth / 3));
    }
  }
}
</style>
